import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { cerrarSesion } from "../../redux/actions/auth/action";

import logo from "../../images/general/logo.png";

// import pdfAPV from '../../pdf/SAAP_Nevasa_AGF_2022_v1.1.pdf'

export class Header extends Component {
  render = () => {
    const { user, cerrarSesion } = this.props;
    return (
      <header>
        <div className="site-top">
          <div className="topbar-transparent border-down" id="top-bar">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <ul className="top-info float-end">
                    {/* <li className="no-menu">
                      <i className="fa fa-phone" />
                      <div className="info-wrapper">
                        <p className="info-title">
                          <a
                            href="tel:+56224119800"
                            target="_blank"
                            without="true"
                            rel="noreferrer"
                          >
                            &nbsp;+(56) 2 2411 9800
                          </a>
                        </p>
                      </div>
                    </li>
                    <li className="no-menu">
                      <i className="fa fa-envelope" />
                      <div className="info-wrapper">
                        <p className="info-title">
                          <a
                            href="mailto:contacto@nevasa.cl"
                            target="_blank"
                            without="true"
                            rel="noreferrer"
                          >
                            &nbsp;contacto@nevasa.cl
                          </a>
                        </p>
                      </div>
                    </li> 
                    <li className="no-menu">
                      <i className="fa fa-map-marker" />
                      <div className="info-wrapper">
                        <p className="info-title">
                          &nbsp;Rosario Norte 555 Piso 15, Las Condes
                        </p>
                      </div>
                    </li>
                  */}
                    {user && Object.keys(user).length > 0 ? (
                      <Fragment>
                        <li>
                          <Link
                            to="/cuenta"
                            className="btn btn-primary btn-inicio"
                          >
                            <i className="fa fa-user" /> Mi Cuenta
                          </Link>
                        </li>
                        <li>
                          <span
                            className="btn btn-dark btn-inicio ml-1"
                            onClick={cerrarSesion}
                          >
                            <i className="fa fa-sign-out-alt ml-1"></i> Cerrar
                            Sesión
                          </span>
                        </li>
                      </Fragment>
                    ) : (
                      <li>
                        <Link
                          to="/inicio-sesion"
                          className="btn btn-primary btn-inicio"
                        >
                          <i className="fa fa-user" /> Acceso Clientes
                        </Link>
                      </li>
                    )}
                    {/* <li>
                      <Link
                        to="/denuncias-reclamos"
                        className="btn btn-border btn-inicio ml-1"
                      >
                        Denuncias y Reclamos
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <nav className="navbar navbar-expand-md">
              <Link className="navbar-brand" to="/">
                <img
                  style={{ width: "250px", verticalAlign: "bottom" }}
                  className="img-fluid"
                  src={logo}
                  alt="logo"
                />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#home_navbar"
                aria-controls="home_navbar"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="home_navbar">
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item">
                    <Link className="nav-link" to="/">
                      INICIO
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="acerca" to="/acerca">
                      ACERCA DE NVS AGF
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="documentos" to="/documentos">
                      DOCUMENTOS
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="documentos" to="/fondos-administrados">
                      FONDOS ADMINISTRADOS
                    </Link>
                  </li>
                  {/* <li className="nav-item dropdown">
                    <Link className="nav-link" to="#" data-toggle="dropdown">
                      APV
                      <i className="fa fa-angle-down"></i>
                    </Link>
                    <ul className="dropdown-menu" role="menu">
                      <li><a target="_blank" rel="noopener noreferrer" href={pdfAPV}>Descargar Formulario Traspaso</a></li>
                    </ul>
                  </li> */}
                  <li className="nav-item">
                    <Link className="documentos" to="/contacto">
                      CONTACTO
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </header>
    );
  };
}

Header.propTypes = {
  user: PropTypes.object.isRequired,
  cerrarSesion: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ cerrarSesion }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
