import React, { Component } from "react";

import { Link } from "react-router-dom";

import icono3 from "../../images/iconos/ico_03.png";
import icono4 from "../../images/iconos/ico_04.png";
import icono5 from "../../images/iconos/ico_05.png";
import icono6 from "../../images/iconos/ico_06.png";
import { Banner, Footer, Header } from "../general";
import {FinancieraPartial} from "../web"

export class Documentos extends Component {
  render = () => {
    const { not_banner } = this.props;
    return (
      <>
        {!not_banner ? (
          <>
            <Header />
            <Banner />
          </>
        ) : null}
        <section className="ts-features">
          <div className="container-fluid">
            <div className="text-center">
              <h2 className="section-title">Documentos</h2>
            </div>
            <div className="text-center mb-5">
              <FinancieraPartial />
            </div>
            <div className="row pt-2">
              <div className="col-lg-3 feature-box1 fondo-manuales">
                <div className="ts-feature text-center">
                  <div className="ts-feature-info">
                    <Link to="/documentos/manuales">
                      <img
                        style={{ width: "60px" }}
                        className="img-fluid mt-4"
                        src={icono3}
                        alt="logo"
                      />
                      <h3 className="ts-feature-title">Manuales</h3>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 feature-box1 fondo-reglamentos">
                <div className="ts-feature text-center">
                  <div className="ts-feature-info">
                    <Link to="/documentos/reglamentos-contratos">
                      <img
                        style={{ width: "60px" }}
                        className="img-fluid mt-4"
                        src={icono4}
                        alt="logo"
                      />
                      <h3 className="ts-feature-title">
                        Reglamentos y Contratos
                      </h3>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 feature-box1 fondo-politicas">
                <div className="ts-feature text-center">
                  <div className="ts-feature-info">
                    <Link to="/documentos/politicas">
                      <img
                        style={{ width: "60px" }}
                        className="img-fluid mt-4"
                        src={icono5}
                        alt="logo"
                      />
                      <h3 className="ts-feature-title">Políticas</h3>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 feature-box1 fondo-hechos">
                <div className="ts-feature text-center">
                  <div className="ts-feature-info">
                    <Link to="/documentos/hechos-esenciales">
                      <img
                        style={{ width: "60px" }}
                        className="img-fluid mt-4"
                        src={icono6}
                        alt="logo"
                      />
                      <h3 className="ts-feature-title">Hechos Esenciales</h3>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {!not_banner ? <Footer /> : null}
      </>
    );
  };
}

export default Documentos;
