import React, { Component } from "react";

import currencyformatter from "currencyformatter.js";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { obtenerMovimientos } from "../../redux/actions/auth/action";
import { Banner, Footer, Header } from "../general";

export class Movimientos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: null,
      cargando: SweetAlert.fire({
        title: "Movimientos",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        text: "Obteniendo movimientos, espere unos segundos",
        icon: "info",
        showConfirmButton: false,
        willOpen: () => {
          SweetAlert.showLoading();
        },
      }),
    };
  }

  componentDidMount() {
    this.cambioYearData();
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  cambioYearData = () => {
    const { obtenerMovimientos } = this.props;
    const { year } = this.state;
    obtenerMovimientos({
      year: year,
    });
    this.setState({
      cargando: SweetAlert.fire({
        title: "Movimientos",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        text: "Obteniendo movimientos, espere unos segundos",
        icon: "info",
        showConfirmButton: false,
        willOpen: () => {
          SweetAlert.showLoading();
        },
      }),
    });
  };

  render = () => {
    const { cargando } = this.state;
    const { movimientos, is_loading_user } = this.props;
    var today_year = new Date().getFullYear();
    var years = _.reverse(_.range(2014, today_year + 1, 1));
    if (!is_loading_user) {
      cargando.close();
    }
    return (
      <>
        <Header />
        <Banner banner="fondos" path="/cuenta/movimientos" />
        <section>
          <div className="container-fluid">
            <div className="text-center">
              <p>
                <Link to="/cuenta">
                  <i className="fa fa-chevron-circle-left"></i> Volver a mi
                  Cuenta
                </Link>
              </p>
              <h2 className="section-title">Movimientos</h2>
            </div>
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="form-group col-3 float-right form-check-inline">
                  <label className="m-2">Año:</label>
                  <select
                    className="form-control"
                    name="year"
                    onChange={this.onChange}
                  >
                    {years.map((y, x) => (
                      <option key={x} value={y}>
                        {y}
                      </option>
                    ))}
                  </select>
                  <button
                    onClick={this.cambioYearData}
                    className="btn btn-primary btn-sm p-2 ml-2"
                  >
                    <i className="fa fa-search"></i>
                  </button>
                </div>
                <div className="table-responsive">
                  <table className="table table-condensed table-hover table-sm table-striped">
                    <thead>
                      <tr className="bg-dark">
                        <td className="text-white text-center">Nº</td>
                        <td className="text-white">Fecha Movimiento</td>
                        <td className="text-white">Fecha Caja</td>
                        <td className="text-white">Número de Cuotas</td>
                        <td className="text-white">Número de Cuenta</td>
                        <td className="text-white">Tipo Movimiento</td>
                        <td className="text-white">Monto</td>
                        <td className="text-white">Fondo</td>
                        <td className="text-white">Serie</td>
                      </tr>
                    </thead>
                    <tbody>
                      {movimientos.length > 0 ? (
                        movimientos.map((mov, x) => (
                          <tr key={x}>
                            <td className="text-center">{x + 1}</td>
                            <td>{mov.fecha_caja}</td>
                            <td>{mov.fecha_mov}</td>
                            <td>{mov.n_cuotas}</td>
                            <td>{mov.n_cuenta}</td>
                            <td>{mov.tipo_mov}</td>
                            <td>
                              {currencyformatter.format(mov.monto, {
                                currency: "USD",
                              })}
                            </td>
                            <td>{mov.fondo}</td>
                            <td>{mov.serie}</td>
                          </tr>
                        ))
                      ) : (
                        <tr className="text-center">
                          <td colSpan="9">Sin Movimientos</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  };
}

Movimientos.propTypes = {
  obtenerMovimientos: PropTypes.func.isRequired,
  movimientos: PropTypes.array.isRequired,
  is_loading_user: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  movimientos: state.auth.movimientos,
  is_loading_user: state.auth.is_loading_user,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerMovimientos }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Movimientos);
