import React, { Component } from "react";
import { Footer, Banner, Header } from "../general";
import { QuienesSomos, Equipo, Documentos, Fondos } from ".";

export class Home extends Component {
  render = () => {
    return (
      <>
        <Header />
        <Banner banner="home" />
        <br />
        <br />
        <QuienesSomos />
        <Equipo />
        <Documentos not_banner />
        <Fondos not_banner />
        <Footer />
      </>
    );
  };
}

export default Home;
