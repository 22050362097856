import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Header, Footer, Banner } from "../general";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { bindActionCreators } from 'redux';

export class Cuenta extends Component {
  render = () => {
    const { user } = this.props;
    return (
      <>
        <Header />
        <Banner banner="fondos" path="/cuenta" />
        <section>
          <div className="container-fluid">
            <div className="text-center">
              <h2 className="section-title">Mi Cuenta</h2>
            </div>
            <div className="row">
              <div className="col-lg-4 offset-lg-1">
                <h3>Mis Datos</h3>
                <h5 className="mb-0">
                  <u>NOMBRE:</u> {user.perfil.nombre}
                </h5>
                <h5 className="mb-0">
                  <u>RUT:</u> {user.perfil.rut}
                </h5>
                <h5 className="mb-0">
                  <u>EMAIL:</u> {user.email}
                </h5>
                <p className="mt-0 small">
                  <Link to="/cuenta/contrasena">
                    ¿cambiar contraseña? presiona aquí
                  </Link>
                </p>
              </div>
              <div className="col-lg-4 offset-lg-1">
                <h3>Información Importate</h3>
                <h5>
                  <u>PATRIMONIO:</u>{" "}
                  <Link to="/cuenta/patrimonio">
                    Ver detalle <i className="fa fa-chevron-circle-right"></i>
                  </Link>
                </h5>
                <h5>
                  <u>MOVIMIENTOS:</u>{" "}
                  <Link to="/cuenta/movimientos">
                    Ver detalle <i className="fa fa-chevron-circle-right"></i>
                  </Link>
                </h5>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  };
}

Cuenta.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(Cuenta);
