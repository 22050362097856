import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import {
  limpiarMensajes,
  nuevaContrasenaToken,
  validarToken,
} from "../../redux/actions/auth/action";
import { Banner, Footer, Header } from "../general";

export class NuevaContrasena extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password1: "",
      password2: "",
      uid: "",
      token: "",
      loading_guardar: "",
    };
  }

  componentDidMount = () => {
    const { validarToken } = this.props;
    const { uid, token } = this.props.match.params;
    validarToken({
      uid: uid,
      token: token,
    });
    this.setState({
      uid: uid,
      token: token,
    });
  };

  onClickNuevaContrasena = (e) => {
    const { nuevaContrasenaToken } = this.props;
    const { password1, password2, uid, token } = this.state;
    var loading = SweetAlert.fire({
      title: "Nueva Contraseña",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      text: "Validando la información ingresada, espere unos segundos",
      icon: "info",
      showConfirmButton: false,
      willOpen: () => {
        SweetAlert.showLoading();
      },
    });
    this.setState({
      loading_guardar: loading,
    });
    nuevaContrasenaToken({
      password1: password1,
      password2: password2,
      uid: uid,
      token: token,
    });
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render = () => {
    const { loading_guardar } = this.state;
    const {
      is_authenticated,
      is_token_valido,
      limpiarMensajes,
      guardar_pass,
      errores_pass,
    } = this.props;
    if (is_authenticated) {
      return <Navigate to="/cuenta" />;
    }
    if (Object.entries(guardar_pass).length > 0) {
      limpiarMensajes();
      loading_guardar.close();
      SweetAlert.fire({
        title: "Nueva contraseña",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        text: guardar_pass.message,
        type: "success",
        confirmButtonText: 'Iniciar Sesión <i class="fas fa-sign-in-alt"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then(() => {
        this.props.history.push("/inicio-sesion");
      });
    }
    if (Object.entries(errores_pass).length > 0) {
      loading_guardar.close();
      if (errores_pass.data && errores_pass.data.message) {
        limpiarMensajes();
        SweetAlert.fire({
          title: "Nueva contraseña",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          text: errores_pass.data.message,
          type: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
      }
    }
    return (
      <>
        <Header />
        <Banner banner="inicio_sesion" path="/nueva-contrasena" />
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                {is_token_valido ? (
                  <form>
                    <div className="form-group">
                      <label>
                        <i className="fa fa-key"></i> Contraseña
                      </label>
                      <input
                        type="password"
                        name="password1"
                        placeholder="Ingresa su nueva contraseña"
                        onChange={this.onChange}
                        className={
                          errores_pass &&
                          errores_pass.data &&
                          errores_pass.data.password1
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                      />
                      {errores_pass &&
                      errores_pass.data &&
                      errores_pass.data.password1
                        ? errores_pass.data.password1.map((err, x) => (
                            <div key={x} className="invalid-feedback">
                              {err}
                            </div>
                          ))
                        : null}
                    </div>
                    <div className="form-group">
                      <label>
                        <i className="fa fa-key"></i> Repita la contraseña
                      </label>
                      <input
                        type="password"
                        name="password2"
                        placeholder="Repita su nueva contraseña"
                        onChange={this.onChange}
                        className={
                          errores_pass &&
                          errores_pass.data &&
                          errores_pass.data.password2
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                      />
                      {errores_pass &&
                      errores_pass.data &&
                      errores_pass.data.password2
                        ? errores_pass.data.password2.map((err, x) => (
                            <div key={x} className="invalid-feedback">
                              {err}
                            </div>
                          ))
                        : null}
                    </div>
                    <div className="button-box">
                      <div className="d-grid gap-2 mt-3 mb-3">
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={this.onClickNuevaContrasena}
                        >
                          Guardar nueva contraseña{" "}
                          <i className="fas fa-paper-plane ml-1"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                ) : (
                  <div>
                    <h3 className="mb-2">
                      Link para cambiar la contraseña no válido
                    </h3>
                    <p>
                      El Link que esta solicitando cambiar la contraseña expiró
                      o no es válido.
                    </p>
                    <p>
                      Puedes solicitar nuevamente recuperar la contraseña en el
                      siguiente botón:
                    </p>
                    <div className="button-box">
                      <div className="d-grid gap-2 mt-3 mb-3">
                        <Link
                          className="btn btn-primary"
                          to="/recuperar-contrasena"
                        >
                          Ingresa aquí
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  };
}

NuevaContrasena.propTypes = {
  nuevaContrasenaToken: PropTypes.func.isRequired,
  validarToken: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  is_authenticated: PropTypes.bool,
  errores_pass: PropTypes.object,
  guardar_pass: PropTypes.object,
  is_token_valido: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  is_authenticated: state.auth.is_authenticated,
  guardar_pass: state.auth.guardar_pass,
  errores_pass: state.auth.errores_pass,
  is_token_valido: state.auth.is_token_valido,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { nuevaContrasenaToken, validarToken, limpiarMensajes },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NuevaContrasena);
