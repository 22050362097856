import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import {
  enviarFormularioContacto,
  limpiarMensajes,
} from "../../redux/actions/auth/action";
import { Banner, Footer, Header } from "../general";

export class Contacto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cargando_form: "",
      nombre: "",
      email: "",
      mensaje: "",
    };
  }

  enviarFormulario = () => {
    const { nombre, email, mensaje } = this.state;
    const { enviarFormularioContacto } = this.props;
    enviarFormularioContacto({
      nombre: nombre,
      email: email,
      mensaje: mensaje,
    });
    this.setState({
      cargando_form: SweetAlert.fire({
        title: "Formulario de Contacto",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        text: "Enviado datos ingresados, espere unos segundos",
        icon: "info",
        showConfirmButton: false,
        willOpen: () => {
          SweetAlert.showLoading();
        },
      }),
    });
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render = () => {
    const { nombre, email, mensaje, cargando_form } = this.state;
    const { formulario_contacto, formulario_contacto_error, limpiarMensajes } =
      this.props;

    if (Object.entries(formulario_contacto_error).length > 0) {
      cargando_form.close();
      if (
        formulario_contacto_error.data &&
        formulario_contacto_error.data.message
      ) {
        SweetAlert.fire({
          title: "Formulario no enviado",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          text: formulario_contacto_error.data.message,
          icon: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            limpiarMensajes();
          }
        });
      }
    }

    if (Object.entries(formulario_contacto).length > 0) {
      cargando_form.close();
      SweetAlert.fire({
        title: "Formulario enviado",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        text: formulario_contacto.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        limpiarMensajes();
        this.setState({
          nombre: "",
          email: "",
          mensaje: "",
        });
      });
    }

    return (
      <>
        <Header />
        <Banner />
        <section className="ts-features">
          <div className="container-fluid">
            <div className="row">
              <div className="col-8 offset-3 col-lg-5">
                <div className="quote_form">
                  <form>
                    <h2 className="column-title title-white">
                      <span>¿Tienes alguna duda o consulta?</span> Formulario
                      Contacto
                    </h2>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name="nombre"
                            placeholder="Nombre Completo"
                            onChange={this.onChange}
                            value={nombre}
                            className={
                              formulario_contacto_error &&
                              formulario_contacto_error.data &&
                              formulario_contacto_error.data.nombre
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {formulario_contacto_error &&
                          formulario_contacto_error.data &&
                          formulario_contacto_error.data.nombre
                            ? formulario_contacto_error.data.nombre.map(
                                (err, x) => (
                                  <div key={x} className="invalid-feedback">
                                    {err}
                                  </div>
                                )
                              )
                            : null}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            onChange={this.onChange}
                            value={email}
                            className={
                              formulario_contacto_error &&
                              formulario_contacto_error.data &&
                              formulario_contacto_error.data.email
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {formulario_contacto_error &&
                          formulario_contacto_error.data &&
                          formulario_contacto_error.data.email
                            ? formulario_contacto_error.data.email.map(
                                (err, x) => (
                                  <div key={x} className="invalid-feedback">
                                    {err}
                                  </div>
                                )
                              )
                            : null}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <textarea
                            name="mensaje"
                            placeholder="Mensaje"
                            rows="6"
                            onChange={this.onChange}
                            value={mensaje}
                            className={
                              formulario_contacto_error &&
                              formulario_contacto_error.data &&
                              formulario_contacto_error.data.mensaje
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          ></textarea>
                          {formulario_contacto_error &&
                          formulario_contacto_error.data &&
                          formulario_contacto_error.data.mensaje
                            ? formulario_contacto_error.data.mensaje.map(
                                (err, x) => (
                                  <div key={x} className="invalid-feedback">
                                    {err}
                                  </div>
                                )
                              )
                            : null}
                        </div>
                      </div>
                    </div>
                    <div className="form-group text-end">
                      <button
                        className="button btn btn-primary"
                        type="button"
                        onClick={this.enviarFormulario}
                      >
                        Enviar Mensaje
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </>
    );
  };
}

Contacto.propTypes = {
  enviarFormularioContacto: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  formulario_contacto: PropTypes.object.isRequired,
  formulario_contacto_error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  formulario_contacto: state.auth.formulario_contacto,
  formulario_contacto_error: state.auth.formulario_contacto_error,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { enviarFormularioContacto, limpiarMensajes },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacto);
