import React from "react";

import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

import logo from "../../images/general/logo.png";

const PrivateRoute = ({ component: Component, auth, ...rest }) => {
  if (auth.is_loading) {
    return (
      <div className="account-body accountbg">
        <div className="row vh-100">
          <div className="col-lg-12 pe-0 align-self-center">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-8 mx-auto text-center">
                <img width={350} src={logo} alt="logo" />
                <h1 className="h5 mt-4 mb-4 fw-normal">NVS AGF</h1>
                <p>
                  <i className="fas fa-circle-notch fa-spin fa-3x text-blue-dark"></i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (!auth.is_authenticated) {
    return <Navigate to="/inicio-sesion" />;
  } else {
    return <Component />;
  }
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
