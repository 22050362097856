export const MENSAJES_LIMPIAR = "MENSAJES_LIMPIAR";
export const FONDOS_OBTENIDO = "FONDOS_OBTENIDO";
export const FONDOS_FALLIDO = "FONDOS_FALLIDO";
export const FONDO_OBTENIDO = "FONDO_OBTENIDO";
export const FONDO_FALLIDO = "FONDO_FALLIDO";
export const DOCUMENTOS_OBTENIDO = "DOCUMENTOS_OBTENIDO";
export const DOCUMENTOS_FALLIDO = "DOCUMENTOS_FALLIDO";
export const FORMULARIO_CONTACTO_OBTENIDO = "FORMULARIO_CONTACTO_OBTENIDO";
export const FORMULARIO_CONTACTO_FALLIDO = "FORMULARIO_CONTACTO_FALLIDO";
export const FORMULARIO_DENUNCIA_OBTENIDO = "FORMULARIO_DENUNCIA_OBTENIDO";
export const FORMULARIO_DENUNCIA_FALLIDO = "FORMULARIO_DENUNCIA_FALLIDO";
export const USUARIO_CARGADO = "USUARIO_CARGADO";
export const USUARIO_CARGANDO = "USUARIO_CARGANDO";
export const AUTENTIFICACION_FALLIDO = "AUTENTIFICACION_FALLIDO";
export const INICIO_SESION_CORRECTO = "INICIO_SESION_CORRECTO";
export const INICIO_SESION_FALLIDO = "INICIO_SESION_FALLIDO";
export const CERRAR_SESION_CORRECTO = "CERRAR_SESION_CORRECTO";
export const REGISTRO_CORRECTO = "REGISTRO_CORRECTO";
export const REGISTRO_FALLIDO = "REGISTRO_FALLIDO";
export const CONTRASENATOKEN_NUEVA = "CONTRASENATOKEN_NUEVA";
export const CONTRASENATOKEN_FALLIDO = "CONTRASENATOKEN_FALLIDO";
export const CONTRASENA_NUEVA = "CONTRASENA_NUEVA";
export const CONTRASENA_FALLIDO = "CONTRASENA_FALLIDO";
export const TOKEN_CORRECTO = "TOKEN_CORRECTO";
export const TOKEN_FALLIDO = "TOKEN_FALLIDO";
export const USUARIO_ACTUALIZACION_OBTENIDO = "USUARIO_ACTUALIZACION_OBTENIDO";
export const USUARIO_ACTUALIZACION_FALLIDO = "USUARIO_ACTUALIZACION_FALLIDO";
export const MOVIMIENTOS_FALLIDO = "MOVIMIENTOS_FALLIDO";
export const MOVIMIENTOS_OBTENIDO = "MOVIMIENTOS_OBTENIDO";
export const PATRIMONIO_FALLIDO = "PATRIMONIO_FALLIDO";
export const PATRIMONIO_OBTENIDO = "PATRIMONIO_OBTENIDO";
export const LOADING_USER_TRUE = "LOADING_USER_TRUE";
export const LOADING_USER_FALSE = "LOADING_USER_FALSE";
