// JS
import "bootstrap/dist/js/bootstrap.bundle.min";
// CSS
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.css";
import "./css/app.css";

import React, { Component } from "react";

import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import {
  Contrasena,
  Cuenta,
  Error404,
  InicioSesion,
  Movimientos,
  NuevaContrasena,
  Patrimonio,
  PrivateRoute,
  RecuperarContrasena,
} from "./components/auth";
import {
  Acerca,
  Contacto,
  Denuncia,
  Documentos,
  Financiera,
  Fondo,
  Fondos,
  Hechos,
  Home,
  Manuales,
  Politicas,
  Reglamentos,
} from "./components/web";
import store from "./redux/store";
import ScrollToTop from "./scrolltotop";

class App extends Component {
  render = () => {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/acerca" element={<Acerca />} />
            <Route exact path="/documentos" element={<Documentos />} />
            <Route exact path="/documentos/manuales" element={<Manuales />} />
            <Route
              exact
              path="/documentos/reglamentos-contratos"
              element={<Reglamentos />}
            />
            <Route exact path="/documentos/politicas" element={<Politicas />} />
            <Route
              exact
              path="/documentos/hechos-esenciales"
              element={<Hechos />}
            />
            <Route
              exact
              path="/documentos/informacion-financiera"
              element={<Financiera />}
            />
            <Route exact path="/fondos-administrados" element={<Fondos />} />
            <Route exact path="/fondo-administrado/:id" element={<Fondo />} />
            <Route exact path="/contacto" element={<Contacto />} />
            <Route exact path="/denuncias-reclamos" element={<Denuncia />} />
            <Route exact path="/inicio-sesion" element={<InicioSesion />} />
            <Route
              exact
              path="/recuperar-contrasena"
              element={<RecuperarContrasena />}
            />
            <Route
              exact
              path="/nueva-contrasena/:uid/:token"
              element={<NuevaContrasena />}
            />

            <Route
              exact
              path="/cuenta"
              element={<PrivateRoute component={Cuenta} />}
            />
            <Route
              exact
              path="/cuenta/movimientos"
              element={<PrivateRoute component={Movimientos} />}
            />
            <Route
              exact
              path="/cuenta/patrimonio"
              element={<PrivateRoute component={Patrimonio} />}
            />
            <Route
              exact
              path="/cuenta/contrasena"
              element={<PrivateRoute component={Contrasena} />}
            />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    );
  };
}

export default App;
