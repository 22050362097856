import React, { Component } from "react";

import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";

import {
  limpiarMensajes,
  nuevaContrasena,
} from "../../redux/actions/auth/action";
import { Banner, Footer, Header } from "../general";

export class Contrasena extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pass_actual: "",
      pass_nueva: "",
      pass_repetida: "",
    };
  }

  cambiarContrasena = () => {
    const { pass_actual, pass_nueva, pass_repetida } = this.state;
    const { nuevaContrasena } = this.props;
    nuevaContrasena({
      pass_actual: pass_actual,
      pass_nueva: pass_nueva,
      pass_repetida: pass_repetida,
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  cambioCompleto = () => {
    // TODO: mejorar el limpiado de mensajes
    const { limpiarMensajes } = this.props;
    limpiarMensajes();
    this.setState({ pass_actual: "", pass_nueva: "", pass_repetida: "" });
  };

  render = () => {
    const { pass_actual, pass_nueva, pass_repetida } = this.state;
    const { contrasena_nueva_error, contrasena_nueva } = this.props;

    return (
      <>
        <Header />
        <Banner banner="fondos" path="/cuenta/contrasena" />
        {Object.entries(contrasena_nueva).length > 0 ? (
          <SweetAlert
            success
            customButtons={
              <>
                <button
                  className="btn btn-primary mr-3"
                  onClick={this.cambioCompleto}
                >
                  Cerrar <i className="fa fa-thumbs-up"></i>
                </button>
              </>
            }
            title="Nueva Contraseña"
            onConfirm={this.cambioCompleto}
          >
            {contrasena_nueva.message}
          </SweetAlert>
        ) : null}
        <section>
          <div className="container-fluid">
            <div className="text-center">
              <p>
                <Link to="/cuenta">
                  <i className="fa fa-chevron-circle-left"></i> Volver a mi
                  Cuenta
                </Link>
              </p>
              <h2 className="section-title">Cambio de Contraseña</h2>
            </div>
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <form>
                  <p className="text-end">
                    <span className="text-danger">*</span> datos obligatorios
                  </p>
                  <div className="form-group">
                    <label>
                      Ingrese la actual contraseña{" "}
                      <small className="text-danger">*</small>
                    </label>
                    <input
                      type="password"
                      name="pass_actual"
                      onChange={this.onChange}
                      value={pass_actual}
                      className={
                        contrasena_nueva_error &&
                        contrasena_nueva_error.data &&
                        contrasena_nueva_error.data.pass_actual
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      placeholder="Contraseña actual"
                    />
                    {contrasena_nueva_error &&
                    contrasena_nueva_error.data &&
                    contrasena_nueva_error.data.pass_actual
                      ? contrasena_nueva_error.data.pass_actual.map(
                          (err, x) => (
                            <div key={x} className="invalid-feedback">
                              {err}
                            </div>
                          )
                        )
                      : null}
                  </div>
                  <div className="form-group">
                    <label>
                      Ingrese una nueva contraseña{" "}
                      <small className="text-danger">*</small>
                    </label>
                    <input
                      type="password"
                      name="pass_nueva"
                      onChange={this.onChange}
                      value={pass_nueva}
                      className={
                        contrasena_nueva_error &&
                        contrasena_nueva_error.data &&
                        contrasena_nueva_error.data.pass_nueva
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      placeholder="Contraseña nueva"
                    />
                    {contrasena_nueva_error &&
                    contrasena_nueva_error.data &&
                    contrasena_nueva_error.data.pass_nueva
                      ? contrasena_nueva_error.data.pass_nueva.map((err, x) => (
                          <div key={x} className="invalid-feedback">
                            {err}
                          </div>
                        ))
                      : null}
                  </div>
                  <div className="form-group">
                    <label>
                      Repita la contraseña nueva{" "}
                      <small className="text-danger">*</small>
                    </label>
                    <input
                      type="password"
                      name="pass_repetida"
                      onChange={this.onChange}
                      value={pass_repetida}
                      className={
                        contrasena_nueva_error &&
                        contrasena_nueva_error.data &&
                        contrasena_nueva_error.data.pass_repetida
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      placeholder="Repita la contraseña nueva"
                    />
                    {contrasena_nueva_error &&
                    contrasena_nueva_error.data &&
                    contrasena_nueva_error.data.pass_repetida
                      ? contrasena_nueva_error.data.pass_repetida.map(
                          (err, x) => (
                            <div key={x} className="invalid-feedback">
                              {err}
                            </div>
                          )
                        )
                      : null}
                  </div>
                  <div className="button-box text-center">
                    <div className="d-grid gap-2">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.cambiarContrasena}
                      >
                        Cambiar Contraseña <i className="fas fa-save"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  };
}

Contrasena.propTypes = {
  nuevaContrasena: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  contrasena_nueva: PropTypes.object.isRequired,
  contrasena_nueva_error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  contrasena_nueva: state.auth.contrasena_nueva,
  contrasena_nueva_error: state.auth.contrasena_nueva_error,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ nuevaContrasena, limpiarMensajes }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Contrasena);
