import React, {Component} from "react";

import {alejandro_b, jose_i, eduardo_c, jorge_s, pamela_g, eduardo_m} from "../../images/directorio"

export class Equipo extends Component {
    render = () => {
        return (
            <>
                <section>
                    <div className="container-fluid no-padding">
                        <div className="text-center">
                            <h2 className="section-title">Principales Ejecutivos</h2>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row justify-content-center">
                                    <div className="col-lg-4">
                                        <div className="ts-team-classic">
                                            <div className="team-img-wrapper text-center">
                                                <img className="img-responsive" alt="eduardo_m" src={eduardo_m}/>
                                            </div>
                                            <div className="ts-team-info">
                                                <h3 className="team-name">Eduardo Muñoz M.</h3>
                                                <p className="team-designation">Gerente General</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container-fluid no-padding">
                        <div className="text-center">
                            <h2 className="section-title">Directorio</h2>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row justify-content-center">
                                    <div className="col-lg-4">
                                        <div className="ts-team-classic">
                                            <div className="team-img-wrapper text-center">
                                                <img className="img-responsive" alt="alejandro_b" src={alejandro_b}/>
                                            </div>
                                            <div className="ts-team-info">
                                                <h3 className="team-name">Alejandro Bertrand</h3>
                                                <p className="team-designation">Presidente</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="ts-team-classic">
                                            <div className="team-img-wrapper text-center">
                                                <img className="img-responsive" alt="jorge_s" src={jorge_s}/>
                                            </div>
                                            <div className="ts-team-info">
                                                <h3 className="team-name">Jorge Simian</h3>
                                                <p className="team-designation">Director</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="ts-team-classic">
                                            <div className="team-img-wrapper text-center">
                                                <img className="img-responsive" alt="eduardo_c" src={eduardo_c}/>
                                            </div>
                                            <div className="ts-team-info">
                                                <h3 className="team-name">Eduardo Castro </h3>
                                                <p className="team-designation">Director</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <div className="row justify-content-center">

                                    <div className="col-lg-4">
                                        <div className="ts-team-classic">
                                            <div className="team-img-wrapper text-center">
                                                <img className="img-responsive" alt="pamela_g" src={pamela_g}/>
                                            </div>
                                            <div className="ts-team-info">
                                                <h3 className="team-name">Pamela Gidi</h3>
                                                <p className="team-designation">Directora</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="ts-team-classic">
                                            <div className="team-img-wrapper text-center">
                                                <img className="img-responsive" alt="jose_i" src={jose_i}/>
                                            </div>
                                            <div className="ts-team-info">
                                                <h3 className="team-name">José Miguel Irarrazaval</h3>
                                                <p className="team-designation">Director</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    };
}

export default Equipo;
