import React, { Component } from "react";

import principal400 from "../../images/slider_principal/400.png";
import principal800 from "../../images/slider_principal/800.png";
import principal1024 from "../../images/slider_principal/1024.png";
import principal1920 from "../../images/slider_principal/1920.png";
import secundario400 from "../../images/slider_secundario/400.jpg";
import secundario800 from "../../images/slider_secundario/800.jpg";
import secundario1024 from "../../images/slider_secundario/1024.jpg";
import secundario1920 from "../../images/slider_secundario/1920.jpg";

export class Banner extends Component {
  render = () => {
    const { banner } = this.props;
    if (banner === "home") {
      return (
        <>
          <img
            src={principal1920}
            alt="banner"
            srcSet={`${principal400} 400w, ${principal800} 800w, ${principal1024} 1024w, ${principal1920} 1920w`}
            sizes="(max-width:400px) 400px, (max-width:300px) 800px, (max-width:1024px) 1024px, (max-width:1920px) 1920px"
          ></img>
        </>
      );
    } else {
      return (
        <>
          <img
            src={secundario1920}
            alt="banner"
            srcSet={`${secundario400} 400w, ${secundario800} 800w, ${secundario1024} 1024w, ${secundario1920} 1920w`}
            sizes="(max-width:400px) 400px, (max-width:300px) 800px, (max-width:1024px) 1024px, (max-width:1920px) 1920px"
          ></img>
        </>
      );
    }
  };
}

export default Banner;
