import React, { Component } from "react";

export class QuienesSomos extends Component {
  render = () => {
    return (
      <>
        <section className="ts-features no-padding">
          <div className="container-fluid no-padding">
            <div className="text-center">
              <h2 className="section-title">Quiénes Somos</h2>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-block">
                  <p className="text-justify">
                    NVS es una Administradora General de Fondos, perteneciente
                    al Grupo Nevasa, que nace para complementar nuestro actual
                    portafolio con productos propios, entregando a nuestros
                    clientes la posibilidad de acceder a fondos de inversión
                    públicos con exposición local e internacional.
                  </p>
                  <p className="text-justify">
                    Todos nuestros fondos son regulados por la Comisión para el
                    Mercado Financiero (CMF) y con la asesoría experta de
                    nuestro equipo de inversiones.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };
}

export default QuienesSomos;
