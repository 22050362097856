import React, { Component } from "react";

import { Documento } from "./";

export class FinancieraPartial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleSection: false,
      doc_seleccionado: "",
      doc_ver_mas: "",
    };
    this.handdleChangeSection = this.handdleChangeSection.bind(this);
  }

  handdleChangeSection = (e) => {
    e.preventDefault();
    this.setState({
      toggleSection: !this.state.toggleSection,
    });
  };

  render = () => {
    const { toggleSection } = this.state;
    return (
      <>
        <section>
          <div className="text-center mb-5">
            <div className="row">
              <div className="text-end col-lg-3 offset-lg-3">
                <button
                  onClick={this.handdleChangeSection}
                  className={`btn btn-nevasa mr-1 ${
                    !toggleSection ? "activo" : "desactivado"
                  }`}
                >
                  Estados Financieros
                </button>
              </div>
              <div className="text-start col-lg-3">
                <button
                  onClick={this.handdleChangeSection}
                  className={`btn btn-nevasa ml-1 ${
                    toggleSection ? "activo" : "desactivado"
                  }`}
                >
                  Memorias
                </button>
              </div>
            </div>
          </div>
          {toggleSection ? (
            <Documento titulo="Memorias" filtro="memorias" />
          ) : (
            <Documento
              titulo="Estados Financieros"
              filtro="estados_financieros"
            />
          )}
        </section>

      </>
    );
  };
}

export default FinancieraPartial;
