import "react-date-picker/dist/DatePicker.css";

import React, { Component } from "react";

import currencyformatter from "currencyformatter.js";
import PropTypes from "prop-types";
import DatePicker from "react-date-picker";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { obtenerPatrimonio } from "../../redux/actions/auth/action";
import { Banner, Footer, Header } from "../general";

export class Patrimonio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fecha: new Date(),
      cargando: SweetAlert.fire({
        title: "Patrimonio",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        text: "Obteniendo patriminio, espere unos segundos",
        icon: "info",
        showConfirmButton: false,
        willOpen: () => {
          SweetAlert.showLoading();
        },
      }),
    };
  }

  componentDidMount() {
    this.cambioYearData();
  }

  onChange = (e) => {
    this.setState({ fecha: e });
  };

  cambioYearData = () => {
    const { obtenerPatrimonio } = this.props;
    const { fecha } = this.state;
    obtenerPatrimonio({
      fecha: fecha,
    });
    this.setState({
      cargando: SweetAlert.fire({
        title: "Patrimonio",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        text: "Obteniendo patriminio, espere unos segundos",
        icon: "info",
        showConfirmButton: false,
        willOpen: () => {
          SweetAlert.showLoading();
        },
      }),
    });
  };

  render = () => {
    const { cargando, fecha } = this.state;
    const { patrimonio, is_loading_user } = this.props;
    if (!is_loading_user) {
      cargando.close();
    }

    return (
      <>
        <Header />
        <Banner banner="fondos" path="/cuenta/patrimonio" />
        <section>
          <div className="container-fluid">
            <div className="text-center">
              <p>
                <Link to="/cuenta">
                  <i className="fa fa-chevron-circle-left"></i> Volver a mi
                  Cuenta
                </Link>
              </p>
              <h2 className="section-title">Patrimonio</h2>
            </div>
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="form-group col-3 mb-1 form-check-inline">
                  <label className="m-2">Fecha:</label>
                  <DatePicker onChange={this.onChange} value={fecha} />
                  <button
                    onClick={this.cambioYearData}
                    className="btn btn-primary btn-sm p-2 ml-2"
                  >
                    <i className="fa fa-search"></i>
                  </button>
                </div>
                <div className="table-responsive">
                  <table className="table table-condensed table-hover table-sm table-striped">
                    <thead>
                      <tr className="bg-dark">
                        <td className="text-white text-center">Nº</td>
                        <td className="text-white">Fondo</td>
                        <td className="text-white">Serie</td>
                        <td className="text-white">Fecha Inversión</td>
                        <td className="text-white">Número de Cuenta</td>
                        <td className="text-white">Número de Cuotas</td>
                        <td className="text-white">Valor Cuota</td>
                        <td className="text-white">Monto</td>
                      </tr>
                    </thead>
                    <tbody>
                      {patrimonio.length > 0 ? (
                        patrimonio.map((pat, x) => (
                          <tr key={x}>
                            <td className="text-center">{x + 1}</td>
                            <td>{pat.fondo}</td>
                            <td>{pat.serie}</td>
                            <td>{pat.fecha_inversion}</td>
                            <td>{pat.n_cuenta}</td>
                            <td>{pat.n_cuotas}</td>
                            <td>
                              {currencyformatter.format(pat.valor_cuota, {
                                currency: "USD",
                              })}
                            </td>
                            <td>
                              {currencyformatter.format(pat.monto, {
                                currency: "USD",
                              })}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr className="text-center">
                          <td colSpan="8">
                            Sin patrimonio para la fecha seleccionada
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  };
}

Patrimonio.propTypes = {
  obtenerPatrimonio: PropTypes.func.isRequired,
  patrimonio: PropTypes.array.isRequired,
  is_loading_user: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  patrimonio: state.auth.patrimonio,
  is_loading_user: state.auth.is_loading_user,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerPatrimonio }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Patrimonio);
