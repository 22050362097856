import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";

import { obtenerFondos } from "../../redux/actions/auth/action";
import { Banner, Footer, Header } from "../general";

export class Fondos extends Component {
  componentDidMount = () => {
    const { obtenerFondos } = this.props;
    obtenerFondos();
  };

  render = () => {
    const { not_banner, fondos } = this.props;
    return (
      <>
        {!not_banner ? (
          <>
            <Header />
            <Banner />
          </>
        ) : null}
        <section>
          <div className="container-fluid">
            <div className="text-center">
              <h2 className="section-title">Fondos Administrados</h2>
            </div>
            <div className="col-lg-10 offset-lg-1">
              <div className="row">
                {fondos.length > 0
                  ? fondos.map((fondo, x) => {
                      return (
                        <div className="col-lg-4 border mb-4 p-3" key={x}>
                          <div className="text-center">
                            <Link to={`/fondo-administrado/${fondo.id}`}>
                              <h5>{fondo.descripcion}</h5>
                            </Link>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </section>
        {!not_banner ? <Footer /> : null}
      </>
    );
  };
}

Fondos.propTypes = {
  obtenerFondos: PropTypes.func.isRequired,
  fondos: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  fondos: state.auth.fondos,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerFondos }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Fondos);
