import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { obtenerDocumentos } from "../../redux/actions/auth/action";

var url_api = process.env.REACT_APP_API_URL;

export class Documento extends Component {
  componentDidMount() {
    const { obtenerDocumentos } = this.props;
    obtenerDocumentos();
  }

  render = () => {
    const { documentos, filtro, titulo } = this.props;
    let documentos_list = documentos.filter(
      (documento) => documento.tipo === filtro
    );

    return (
      <>
        {documentos_list.length > 0 ? (
          <div className="container-fluid mb-4">
            <div className="col-lg-10 offset-lg-1">
              {titulo ? <h4 className="text-center">{titulo}</h4> : null}
              <div className="row justify-content-center">
                {documentos_list.map((documento, x) => (
                  <div className="col-lg-3" key={x}>
                    <div className="text-center">
                      <div className="">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`${url_api}/static/pdf/${documento.pdf_nombre}`}
                        >
                          <i
                            className="fa fa-file fa-2x"
                            style={{ color: "#fa4616" }}
                          ></i>
                          <p style={{ color: "#333" }} className="mt-2">
                            {documento.nombre}
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  };
}

Documento.propTypes = {
  obtenerDocumentos: PropTypes.func.isRequired,
  documentos: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  documentos: state.auth.documentos,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerDocumentos }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Documento);
