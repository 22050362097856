import React, { Component } from "react";
import { Documento } from ".";
import { Footer, Banner, Header } from "../general";

export class Hechos extends Component {
  render = () => {
    return (
      <>
        <Header />
        <Banner />
        <section>
          <Documento filtro="hechos_esenciales" />
        </section>
        <Footer />
      </>
    );
  };
}

export default Hechos;
