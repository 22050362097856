import React, { Component } from "react";

import { Link } from "react-router-dom";

export class Footer extends Component {
  render = () => {
    return (
      <footer className="footer" id="footer">
        <div className="footer-top footer-top-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-sm-6 col-xs-12 mt-3">
                <h4>
                  <i className="fa fa-map-marker" /> Dirección
                </h4>
                <p className="text-black">
                  Rosario Norte 555, Piso 15,
                  <br /> Oficina 1501, Las Condes
                </p>
              </div>
              <div className="col-lg-3 col-sm-6 col-xs-12 mt-3">
                <h4>
                  <i className="fa fa-phone" /> Llámanos
                </h4>
                <p className="text-black">
                  <a
                    href="tel:+56224119800"
                    without="true"
                    rel="noreferrer"
                    target="_blank"
                  >
                    +562 2411 9800
                  </a>
                </p>
              </div>
              <div className="col-lg-3 col-sm-6 col-xs-12 mt-3">
                <h4>
                  <i className="fa fa-envelope" /> Email
                </h4>
                <p>
                  <a
                    className="text-dark"
                    href="mailto:contacto@nevasa.cl"
                    without="true"
                    rel="noreferrer"
                    target="_blank"
                  >
                    contacto@nevasa.cl
                  </a>
                </p>
              </div>
              <div className="col-lg-3 col-sm-6 col-xs-12 mt-3">
                <h4>
                  <i className="fa fa-file-circle-exclamation" /> Denuncias y
                  Reclamos
                </h4>
                <p>
                  <Link className="text-dark" to="/denuncias-reclamos">
                    Ir al Formulario
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="copyright-info">
                  <span>
                    <i className="fa fa-copyright" /> NVS AGF. Todos los
                    derechos reservados.
                    <a
                      href="http://www.axioma.la/"
                      without="true"
                      rel="noreferrer"
                      target="_blank"
                      className="color-white"
                    >
                      <strong> Powered by Axioma Fintech</strong>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  };
}

export default Footer;
