import {
  DOCUMENTOS_FALLIDO,
  DOCUMENTOS_OBTENIDO,
  FONDO_FALLIDO,
  FONDO_OBTENIDO,
  FONDOS_FALLIDO,
  FONDOS_OBTENIDO,
  FORMULARIO_CONTACTO_FALLIDO,
  FORMULARIO_CONTACTO_OBTENIDO,
  FORMULARIO_DENUNCIA_FALLIDO,
  FORMULARIO_DENUNCIA_OBTENIDO,
  MENSAJES_LIMPIAR,
  AUTENTIFICACION_FALLIDO,
  CERRAR_SESION_CORRECTO,
  CONTRASENA_FALLIDO,
  CONTRASENA_NUEVA,
  CONTRASENATOKEN_FALLIDO,
  CONTRASENATOKEN_NUEVA,
  INICIO_SESION_CORRECTO,
  INICIO_SESION_FALLIDO,
  LOADING_USER_FALSE,
  LOADING_USER_TRUE,
  MOVIMIENTOS_FALLIDO,
  MOVIMIENTOS_OBTENIDO,
  PATRIMONIO_FALLIDO,
  PATRIMONIO_OBTENIDO,
  TOKEN_CORRECTO,
  TOKEN_FALLIDO,
  USUARIO_ACTUALIZACION_FALLIDO,
  USUARIO_ACTUALIZACION_OBTENIDO,
  USUARIO_CARGADO,
  USUARIO_CARGANDO,
} from "../actions/auth/types";

const initialState = {
  token: localStorage.getItem("token"),
  is_authenticated: false,
  is_loading: true,
  is_loading_user: true,
  is_token_valido: false,
  user: {},
  errores: {},
  errores_pass: {},
  guardar: {},
  guardar_pass: {},
  guardar_registro: {},
  errores_registro: {},
  registro_valido: {},
  contrasena_nueva: {},
  contrasena_nueva_error: {},
  actualizacion: {},
  actualizacion_error: {},
  patrimonio: [],
  movimientos: [],
  fondos: [],
  fondo: {},
  documentos: [],
  formulario_contacto: {},
  formulario_contacto_error: {},
  formulario_denuncia: {},
  formulario_denuncia_error: {},
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case MOVIMIENTOS_OBTENIDO:
      return {
        ...state,
        movimientos: action.payload,
        is_loading_user: false,
      };
    case MOVIMIENTOS_FALLIDO:
      return {
        ...state,
        movimientos: [],
        is_loading_user: false,
      };
    case PATRIMONIO_OBTENIDO:
      return {
        ...state,
        patrimonio: action.payload,
        is_loading_user: false,
      };
    case PATRIMONIO_FALLIDO:
      return {
        ...state,
        patrimonio: [],
        is_loading_user: false,
      };
    case USUARIO_ACTUALIZACION_OBTENIDO:
      return {
        ...state,
        actualizacion: action.payload,
        actualizacion_error: {},
      };
    case USUARIO_ACTUALIZACION_FALLIDO:
      return {
        ...state,
        actualizacion: {},
        actualizacion_error: action.payload,
      };
    case USUARIO_CARGANDO:
      return {
        ...state,
        is_loading: true,
        errores: {},
      };
    case USUARIO_CARGADO:
      return {
        ...state,
        is_authenticated: true,
        is_loading: false,
        user: action.payload,
        errores: {},
      };
    case INICIO_SESION_CORRECTO:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        ...action.payload,
        is_authenticated: true,
        is_loading: false,
        errores: {},
        user: action.payload.user,
      };
    case AUTENTIFICACION_FALLIDO:
    case INICIO_SESION_FALLIDO:
      localStorage.clear();
      return {
        ...state,
        token: null,
        user: {},
        is_authenticated: false,
        is_loading: false,
        errores: action.payload,
      };
    case CERRAR_SESION_CORRECTO:
      localStorage.clear();
      return {
        ...state,
        token: null,
        user: {},
        is_authenticated: false,
        is_loading: false,
        errores: {},
      };
    case CONTRASENATOKEN_NUEVA:
      return {
        ...state,
        guardar_pass: action.payload,
        errores_pass: {},
      };
    case CONTRASENATOKEN_FALLIDO:
      return {
        ...state,
        errores_pass: action.payload,
        guardar_pass: {},
      };
    case CONTRASENA_NUEVA:
      return {
        ...state,
        contrasena_nueva: action.payload,
        contrasena_nueva_error: {},
      };
    case CONTRASENA_FALLIDO:
      return {
        ...state,
        contrasena_nueva_error: action.payload,
        contrasena_nueva: {},
      };
    case TOKEN_CORRECTO:
      return {
        ...state,
        is_token_valido: action.payload.validacion,
      };
    case TOKEN_FALLIDO:
      return {
        ...state,
        is_token_valido: false,
      };
    case LOADING_USER_FALSE:
      return {
        ...state,
        is_loading_user: false,
      };
    case LOADING_USER_TRUE:
      return {
        ...state,
        is_loading_user: true,
      };
    case FORMULARIO_DENUNCIA_OBTENIDO:
      return {
        ...state,
        formulario_denuncia: action.payload,
        formulario_denuncia_error: {},
      };
    case FORMULARIO_DENUNCIA_FALLIDO:
      return {
        ...state,
        formulario_denuncia_error: action.payload,
        formulario_denuncia: {},
      };
    case FORMULARIO_CONTACTO_OBTENIDO:
      return {
        ...state,
        formulario_contacto: action.payload,
        formulario_contacto_error: {},
      };
    case FORMULARIO_CONTACTO_FALLIDO:
      return {
        ...state,
        formulario_contacto_error: action.payload,
        formulario_contacto: {},
      };
    case DOCUMENTOS_OBTENIDO:
      return {
        ...state,
        documentos: action.payload,
      };
    case DOCUMENTOS_FALLIDO:
      return {
        ...state,
        documentos: [],
      };
    case FONDOS_OBTENIDO:
      return {
        ...state,
        fondos: action.payload,
      };
    case FONDOS_FALLIDO:
      return {
        ...state,
        fondos: [],
      };
    case FONDO_OBTENIDO:
      return {
        ...state,
        fondo: action.payload,
      };
    case FONDO_FALLIDO:
      return {
        ...state,
        fondo: {},
      };
    case MENSAJES_LIMPIAR:
      return {
        ...state,
        formulario_contacto: {},
        formulario_contacto_error: {},
        formulario_denuncia: {},
        formulario_denuncia_error: {},
        errores: {},
        errores_pass: {},
        guardar: {},
        guardar_pass: {},
        guardar_registro: {},
        errores_registro: {},
        registro_valido: {},
        contrasena_nueva: {},
        contrasena_nueva_error: {},
        actualizacion: {},
        actualizacion_error: {},
      };
    default:
      return state;
  }
}
