import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import {
  iniciarSesion,
  limpiarMensajes,
} from "../../redux/actions/auth/action";
import { Banner, Footer, Header } from "../general";

export class InicioSesion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
  }

  componentWillUnmount = () => {
    const { limpiarMensajes } = this.props;
    limpiarMensajes();
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { iniciarSesion } = this.props;
    iniciarSesion(this.state.email, this.state.password);
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render = () => {
    const { is_authenticated, limpiarMensajes, errores } = this.props;
    const { email, password } = this.state;
    if (is_authenticated) {
      return <Navigate to="/cuenta" />;
    }

    if (
      errores &&
      Object.entries(errores).length > 0 &&
      Object.entries(errores.data).length > 0 &&
      errores.data.inicio_sesion &&
      Object.entries(errores.data.inicio_sesion).length > 0
    ) {
      limpiarMensajes();
      var error = "";
      for (const err in errores.data.inicio_sesion) {
        error = error + `<h5>${errores.data.inicio_sesion[err]}</h5>`;
      }
      SweetAlert.fire({
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        title: "Inicio de sesión",
        html: error,
        type: "error",
        confirmButtonText:
          'Volver a intentar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
    }
    return (
      <>
        <Header />
        <Banner banner="inicio_sesion" path="/inicio-sesion" />
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <form
                  onSubmit={this.onSubmit}
                  className="form-iniciar-sesion d-none d-sm-block"
                >
                  <div className="form-group">
                    <label>
                      <i className="fa fa-envelope"></i> Email{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Ingresa su email"
                      onChange={this.onChange}
                      value={email}
                      className={
                        errores && errores.data && errores.data.email
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                    />
                    {errores && errores.data && errores.data.email
                      ? errores.data.email.map((err, x) => (
                          <div key={x} className="invalid-feedback">
                            {err}
                          </div>
                        ))
                      : null}
                  </div>
                  <div className="form-group">
                    <label>
                      <i className="fa fa-lock"></i> Contraseña{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="password"
                      name="password"
                      placeholder="Ingrese su contraseña"
                      onChange={this.onChange}
                      value={password}
                      className={
                        errores && errores.data && errores.data.password
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                    />
                    {errores && errores.data && errores.data.password
                      ? errores.data.password.map((err, x) => (
                          <div key={x} className="invalid-feedback">
                            {err}
                          </div>
                        ))
                      : null}
                  </div>
                  <div className="button-box">
                    <div className="d-grid gap-2 mt-3 mb-3">
                      <button type="submit" className="btn btn-primary">
                        Iniciar sesión{" "}
                        <i className="fas fa-sign-in-alt ml-1"></i>
                      </button>
                    </div>
                    <div className="text-center">
                      <Link to="/recuperar-contrasena">
                        ¿Necesitas recuperar tu contraseña?
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  };
}

InicioSesion.propTypes = {
  iniciarSesion: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  is_authenticated: PropTypes.bool,
  errores: PropTypes.object,
};

const mapStateToProps = (state) => ({
  is_authenticated: state.auth.is_authenticated,
  errores: state.auth.errores,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ iniciarSesion, limpiarMensajes }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(InicioSesion);
