import React from "react";

import { withRouter } from "../general/with_router";

import { Banner, Footer, Header } from "../general";

const Error404 = ({ history }) => (
  <>
    <Header />
    <Banner />
    <section>
      <div className="login-register-area mb-60px mt-53px">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-12 ml-auto mr-auto">
              <div className="login-register-wrapper">
                <div className="login-register-tab-list nav">
                  <h3>Página o archivo no encontrado</h3>
                  <p>
                    La página o archivo que buscas puede haber sido removido,
                    cambiado de nombre, o temporalmente no está disponible.
                  </p>
                  <div className="mt-3">
                    <button
                      className="btn btn-primary"
                      onClick={history.goBack}
                    >
                      <i className="fa fa-angle-left"></i> Volver
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </>
);

export default withRouter(Error404);
