import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import {
  enviarFormularioDenuncia,
  limpiarMensajes,
} from "../../redux/actions/auth/action";
import { Banner, Footer, Header } from "../general";

export class Denuncia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cargando_form: "",
      nombre: "",
      email: "",
      mensaje: "",
    };
  }

  enviarFormulario = () => {
    const { nombre, email, mensaje } = this.state;
    const { enviarFormularioDenuncia } = this.props;
    enviarFormularioDenuncia({
      nombre: nombre,
      email: email,
      mensaje: mensaje,
    });
    this.setState({
      cargando_form: SweetAlert.fire({
        title: "Formulario de Denuncia/Reclamo",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        text: "Enviado datos ingresados, espere unos segundos",
        icon: "info",
        showConfirmButton: false,
        willOpen: () => {
          SweetAlert.showLoading();
        },
      }),
    });
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render = () => {
    const { nombre, email, mensaje, cargando_form } = this.state;
    const { formulario_denuncia, formulario_denuncia_error, limpiarMensajes } =
      this.props;

    if (Object.entries(formulario_denuncia_error).length > 0) {
      cargando_form.close();
      if (
        formulario_denuncia_error.data &&
        formulario_denuncia_error.data.message
      ) {
        SweetAlert.fire({
          title: "Formulario no enviado",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          text: formulario_denuncia_error.data.message,
          icon: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            limpiarMensajes();
          }
        });
      }
    }

    if (Object.entries(formulario_denuncia).length > 0) {
      cargando_form.close();
      SweetAlert.fire({
        title: "Formulario enviado",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        text: formulario_denuncia.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        limpiarMensajes();
        this.setState({
          nombre: "",
          email: "",
          mensaje: "",
        });
      });
    }
    return (
      <>
        <Header />
        <Banner />
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center">
                  <h3 className="section-title">Denuncias</h3>
                </div>
                <div className="text-block mrb-40">
                  <p className="text-justify">
                    NVS AGF pone a disposición este canal de comunicación a
                    través del cual se podrán hacer llegar a la Alta
                    Administración de la Compañía, denuncias relativas a fraudes
                    y delitos contemplados en la Ley N° 20.393 relacionados con
                    Lavado de Activos, Financiamiento del Terrorismo, Cohecho y
                    Receptación.
                  </p>
                  <p className="text-justify">
                    Esta comunicación será conocida únicamente por el Oficial de
                    Riesgo y Cumplimiento Sr. René Moll C., quien la mantendrá
                    bajo estricta confidencialidad y solo comunicará los
                    antecedentes pertinentes al Directorio de la Sociedad, con
                    el objetivo de evaluar si corresponde iniciar una
                    investigación.
                  </p>
                </div>
                <div className="text-center">
                  <h3 className="section-title">Reclamos</h3>
                </div>
                <div className="text-block mrb-40">
                  <p className="text-justify">
                    NVS AGF dispone también de esta vía para que los clientes,
                    proveedores o contrapartes puedan manifestar su
                    inconformidad respecto de temas comerciales o de operación
                    de sus productos, así como su opinión respecto de alguna
                    conducta que considere inadecuada de parte de algún
                    colaborador de nuestra empresa.
                  </p>
                  <p className="text-justify">
                    De no querer remitir la DENUNCIA o RECLAMO por esta vía, se
                    pueden enviar los antecedentes del caso en un sobre sellado
                    dirigido al Directorio de la Compañía o al Oficial de Riesgo
                    y Cumplimiento a la dirección Rosario Norte 555, Piso 15,
                    Oficina 1501, Las Condes, Santiago. En todos los casos, NVS
                    AGF garantiza que éstos se mantendrán en absoluta reserva.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          className="container-fluid"
          style={{
            backgroundColor: "#333e48",
            color: "#FFF",
            margin: "30px 0 60px 0",
            padding: "60px 0 40px 0",
          }}
        >
          <div className="row">
            <div className="col-md-12 text-center">
              <h4
                className="section-title"
                style={{ textAlign: "center", color: "#FFF" }}
              >
                Formulario denuncias y reclamos
              </h4>
            </div>
            <div className="col-md-4 offset-md-4">
              <form>
                <h2 className="column-title color-white text-center">
                  <span>¿Tienes alguna duda o consulta?</span>
                </h2>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group mb-2">
                      <input
                        type="text"
                        name="nombre"
                        placeholder="Nombre Completo"
                        onChange={this.onChange}
                        value={nombre}
                        className={
                          formulario_denuncia_error &&
                          formulario_denuncia_error.data &&
                          formulario_denuncia_error.data.nombre
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                      />
                      {formulario_denuncia_error &&
                      formulario_denuncia_error.data &&
                      formulario_denuncia_error.data.nombre
                        ? formulario_denuncia_error.data.nombre.map(
                            (err, x) => (
                              <div key={x} className="invalid-feedback">
                                {err}
                              </div>
                            )
                          )
                        : null}
                    </div>
                    <div className="form-group mb-2">
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        onChange={this.onChange}
                        value={email}
                        className={
                          formulario_denuncia_error &&
                          formulario_denuncia_error.data &&
                          formulario_denuncia_error.data.email
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                      />
                      {formulario_denuncia_error &&
                      formulario_denuncia_error.data &&
                      formulario_denuncia_error.data.email
                        ? formulario_denuncia_error.data.email.map((err, x) => (
                            <div key={x} className="invalid-feedback">
                              {err}
                            </div>
                          ))
                        : null}
                    </div>
                    <div className="form-group mb-2">
                      <textarea
                        name="mensaje"
                        placeholder="Mensaje"
                        rows="6"
                        onChange={this.onChange}
                        value={mensaje}
                        className={
                          formulario_denuncia_error &&
                          formulario_denuncia_error.data &&
                          formulario_denuncia_error.data.mensaje
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                      ></textarea>
                      {formulario_denuncia_error &&
                      formulario_denuncia_error.data &&
                      formulario_denuncia_error.data.mensaje
                        ? formulario_denuncia_error.data.mensaje.map(
                            (err, x) => (
                              <div key={x} className="invalid-feedback">
                                {err}
                              </div>
                            )
                          )
                        : null}
                    </div>
                  </div>
                </div>
                <div className="form-group text-end">
                  <button
                    className="button btn btn-primary"
                    type="button"
                    onClick={this.enviarFormulario}
                  >
                    Enviar Mensaje
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  };
}

Denuncia.propTypes = {
  enviarFormularioDenuncia: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  formulario_denuncia: PropTypes.object.isRequired,
  formulario_denuncia_error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  formulario_denuncia: state.auth.formulario_denuncia,
  formulario_denuncia_error: state.auth.formulario_denuncia_error,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { enviarFormularioDenuncia, limpiarMensajes },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Denuncia);
