import React, { Component } from "react";
import { Footer, Banner, Header } from "../general";
import { QuienesSomos, Equipo } from ".";

export class Acerca extends Component {
  render = () => {
    return (
      <>
        <Header />
        <Banner />
        <br />
        <br />
        <QuienesSomos />
        <Equipo />
        <Footer />
      </>
    );
  };
}

export default Acerca;
