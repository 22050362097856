import axios from "axios";

import {
  AUTENTIFICACION_FALLIDO,
  CERRAR_SESION_CORRECTO,
  CONTRASENA_FALLIDO,
  CONTRASENA_NUEVA,
  CONTRASENATOKEN_FALLIDO,
  CONTRASENATOKEN_NUEVA,
  INICIO_SESION_CORRECTO,
  INICIO_SESION_FALLIDO,
  LOADING_USER_TRUE,
  MOVIMIENTOS_FALLIDO,
  MOVIMIENTOS_OBTENIDO,
  PATRIMONIO_FALLIDO,
  PATRIMONIO_OBTENIDO,
  TOKEN_CORRECTO,
  TOKEN_FALLIDO,
  USUARIO_ACTUALIZACION_FALLIDO,
  USUARIO_ACTUALIZACION_OBTENIDO,
  USUARIO_CARGADO,
  USUARIO_CARGANDO,
  DOCUMENTOS_FALLIDO,
  DOCUMENTOS_OBTENIDO,
  FONDO_FALLIDO,
  FONDO_OBTENIDO,
  FONDOS_FALLIDO,
  FONDOS_OBTENIDO,
  FORMULARIO_CONTACTO_FALLIDO,
  FORMULARIO_CONTACTO_OBTENIDO,
  FORMULARIO_DENUNCIA_FALLIDO,
  FORMULARIO_DENUNCIA_OBTENIDO,
  MENSAJES_LIMPIAR,
} from "./types";

var url_api = process.env.REACT_APP_API_URL;
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const tokenConfig = (getState) => {
  const token = getState().auth.token;
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }
  return config;
};

export const limpiarMensajes = () => (dispatch) => {
  dispatch({
    type: MENSAJES_LIMPIAR,
  });
};

export const obtenerFondos = () => (dispatch, getState) => {
  axios
    .get(`${url_api}/api/documents/fondos`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: FONDOS_OBTENIDO,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: FONDOS_FALLIDO,
        payload: err.response,
      });
    });
};

export const obtenerFondo = (id) => (dispatch, getState) => {
  axios
    .get(`${url_api}/api/documents/fondo/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: FONDO_OBTENIDO,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: FONDO_FALLIDO,
        payload: err.response,
      });
    });
};

export const obtenerDocumentos = () => (dispatch, getState) => {
  axios
    .get(`${url_api}/api/documents/documentos`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: DOCUMENTOS_OBTENIDO,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: DOCUMENTOS_FALLIDO,
        payload: err.response,
      });
    });
};

export const enviarFormularioContacto = (data) => (dispatch, getState) => {
  axios
    .post(`${url_api}/api/users/form/contacto`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: FORMULARIO_CONTACTO_OBTENIDO,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: FORMULARIO_CONTACTO_FALLIDO,
        payload: err.response,
      });
    });
};

export const enviarFormularioDenuncia = (data) => (dispatch, getState) => {
  axios
    .post(`${url_api}/api/users/form/denuncia`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: FORMULARIO_DENUNCIA_OBTENIDO,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: FORMULARIO_DENUNCIA_FALLIDO,
        payload: err.response,
      });
    });
};

export const cargarUsuario = () => (dispatch, getState) => {
  dispatch({ type: USUARIO_CARGANDO });
  axios
    .get(`${url_api}/api/users/usuario`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: USUARIO_CARGADO,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: AUTENTIFICACION_FALLIDO,
        payload: err.response,
      });
    });
};

export const iniciarSesion = (email, password) => (dispatch) => {
  const body = JSON.stringify({ email, password });
  axios
    .post(`${url_api}/api/users/inicio-sesion`, body, config)
    .then((res) => {
      dispatch({
        type: INICIO_SESION_CORRECTO,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: INICIO_SESION_FALLIDO,
        payload: err.response,
      });
    });
};

export const validarToken = (data) => (dispatch) => {
  axios
    .post(`${url_api}/api/users/validar-token`, data, config)
    .then((res) => {
      dispatch({
        type: TOKEN_CORRECTO,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: TOKEN_FALLIDO,
        payload: err.response,
      });
    });
};

export const nuevaContrasenaToken = (data) => (dispatch) => {
  axios
    .post(`${url_api}/api/users/nueva-contrasena-token`, data, config)
    .then((res) => {
      dispatch({
        type: CONTRASENATOKEN_NUEVA,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: CONTRASENATOKEN_FALLIDO,
        payload: err.response,
      });
    });
};

export const nuevaContrasena = (data) => (dispatch, getState) => {
  axios
    .post(`${url_api}/api/users/nueva-contrasena`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: CONTRASENA_NUEVA,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: CONTRASENA_FALLIDO,
        payload: err.response,
      });
    });
};

export const cambioContrasena = (email) => (dispatch) => {
  axios
    .post(`${url_api}/api/users/recuperar-contrasena`, { email: email }, config)
    .then((res) => {
      dispatch({
        type: CONTRASENATOKEN_NUEVA,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: CONTRASENATOKEN_FALLIDO,
        payload: err.response,
      });
    });
};

export const cerrarSesion = () => (dispatch, getState) => {
  axios
    .post(`${url_api}/api/users/cerrar-sesion`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: MENSAJES_LIMPIAR });
      dispatch({ type: CERRAR_SESION_CORRECTO });
    })
    .catch((err) => {
      dispatch({
        type: INICIO_SESION_FALLIDO,
        payload: err.response,
      });
    });
};

export const actualizacionUsuario = (data) => (dispatch, getState) => {
  axios
    .put(`${url_api}/api/users/actualizacion`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: USUARIO_ACTUALIZACION_OBTENIDO,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: USUARIO_ACTUALIZACION_FALLIDO,
        payload: err.response,
      });
    });
};

export const obtenerMovimientos = (data) => (dispatch, getState) => {
  dispatch({ type: LOADING_USER_TRUE });
  axios
    .post(`${url_api}/api/users/movimientos`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: MOVIMIENTOS_OBTENIDO,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: MOVIMIENTOS_FALLIDO,
        payload: err.response,
      });
    });
};

export const obtenerPatrimonio = (data) => (dispatch, getState) => {
  dispatch({ type: LOADING_USER_TRUE });
  axios
    .post(`${url_api}/api/users/patrimonio`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: PATRIMONIO_OBTENIDO,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: PATRIMONIO_FALLIDO,
        payload: err.response,
      });
    });
};
