import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "../general/with_router";
import { obtenerFondo } from "../../redux/actions/auth/action";
import { Banner, Footer, Header } from "../general";

var url_api = process.env.REACT_APP_API_URL;

class Fondo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleSection: false,
      doc_seleccionado: "",
      doc_ver_mas: "",
    };
    this.handdleChangeSection = this.handdleChangeSection.bind(this);
  }

  componentDidMount = () => {
    const { obtenerFondo } = this.props;
    const { id } = this.props.match.params;
    obtenerFondo(id);
  };

  verDocumentos = (doc) => {
    this.setState({
      doc_seleccionado: doc,
      doc_ver_mas: "",
    });
  };

  verMas = (doc) => {
    this.setState({
      doc_ver_mas: doc,
    });
  };

  handdleChangeSection = (e) => {
    e.preventDefault();
    this.setState({
      toggleSection: !this.state.toggleSection,
    });
  };

  render = () => {
    const { fondo } = this.props;
    const { toggleSection, doc_seleccionado, doc_ver_mas } = this.state;

    return (
      <>
        <Header />
        <Banner />
        <div className="container-fluid">
          <div className="text-center">
            <h2 className="section-title mt-5">{fondo.nombre}</h2>
          </div>
          <div className="text-center mb-5">
            <div className="row">
              <div className="text-end col-lg-3 offset-lg-3">
                <button
                  onClick={this.handdleChangeSection}
                  disabled={!toggleSection}
                  className="btn btn-dark btn-inicio mr-1"
                >
                  Descarga de Documentos
                </button>
              </div>
              <div className="text-start col-lg-3">
                <button
                  onClick={this.handdleChangeSection}
                  disabled={toggleSection}
                  className="btn btn-dark btn-inicio ml-1"
                >
                  Información General
                </button>
              </div>
            </div>
          </div>
          {toggleSection ? (
            <div className="col-lg-8 offset-lg-2">
              <div className="row">
                <div className="col-lg-4">
                  <h4 className="mb-0">Fecha Inicio Operaciones</h4>
                  <p>{fondo.fecha}</p>
                </div>
                <div className="col-lg-4">
                  <h4 className="mb-0">Patrimonio</h4>
                  <p>{fondo.patrimonio}</p>
                </div>
                <div className="col-lg-4">
                  <h4 className="mb-0">Abogados</h4>
                  <p>{fondo.abogados}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <h4 className="mb-0">Moneda</h4>
                  <p>{fondo.moneda}</p>
                </div>
                <div className="col-lg-4">
                  <h4 className="mb-0">Auditores</h4>
                  <p>{fondo.auditores}</p>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-lg-10 offset-lg-1">
              <div className="row justify-content-center mb-5">
                <div className="col-lg-1 text-center">
                  <button
                      type="button"
                      className={`btn btn-link btn-fondo text-uppercase font-size-13 ${
                          doc_seleccionado === "hechos" ? "color-nevasa" : ""
                      }`}
                      onClick={this.verDocumentos.bind(this, "hechos")}
                  >
                    Hechos Esenciales
                  </button>
                  {fondo.hechos_esenciales &&
                  fondo.hechos_esenciales.length > 0 ? (
                      <ul
                          className={`p-4 ${
                              doc_seleccionado === "hechos" ? "" : "d-none"
                          }`}
                      >
                        {fondo.hechos_esenciales
                            ? fondo.hechos_esenciales.map((documento, x) => (
                                <li
                                    key={x}
                                    className={
                                      x < 4 || doc_ver_mas === "hechos"
                                          ? ""
                                          : "d-none"
                                    }
                                >
                                  <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={`${
                                          documento.pdf_nombre
                                              ? `${url_api}/static/pdf/${documento.pdf_nombre}`
                                              : documento.url
                                      }`}
                                  >
                                    <i
                                        className="fa fa-file"
                                        style={{color: "#fa4616", fontSize: "16px"}}
                                    />
                                    <p style={{color: "#333", fontSize: "13px"}}>
                                      {documento.descripcion}
                                    </p>
                                  </a>
                                </li>
                            ))
                            : null}
                        {doc_ver_mas === "hechos" ? null : (
                            <li>
                              <button
                                  onClick={this.verMas.bind(this, "hechos")}
                                  type="button"
                                  className="btn btn-link color-nevasa font-size-13"
                              >
                                Ver más <i className="fa fa-angle-double-down"></i>
                              </button>
                            </li>
                        )}
                      </ul>
                  ) : null}
                </div>
                <div className="col-lg-1 text-center">
                  <div className="border-left pl-0">
                    <button
                        type="button"
                        className={`btn btn-link btn-fondo text-uppercase font-size-13 ${
                            doc_seleccionado === "acta_asamblea" ? "color-nevasa" : ""
                        }`}
                        onClick={this.verDocumentos.bind(this, "acta_asamblea")}
                    >
                      Actas Asambleas
                    </button>
                  </div>
                  {fondo.acta_asamblea && fondo.acta_asamblea.length > 0 ? (
                      <ul
                          className={`p-4 ${
                              doc_seleccionado === "acta_asamblea" ? "" : "d-none"
                          }`}
                      >
                        {fondo.acta_asamblea
                            ? fondo.acta_asamblea.map((documento, x) => (
                                <li
                                    key={x}
                                    className={
                                      x < 4 || doc_ver_mas === "acta_asamblea"
                                          ? ""
                                          : "d-none"
                                    }
                                >
                                  <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={`${
                                          documento.pdf_nombre
                                              ? `${url_api}/static/pdf/${documento.pdf_nombre}`
                                              : documento.url
                                      }`}
                                  >
                                    <i
                                        className="fa fa-file"
                                        style={{color: "#fa4616", fontSize: "16px"}}
                                    />
                                    <p style={{color: "#333", fontSize: "13px"}}>
                                      {documento.descripcion}
                                    </p>
                                  </a>
                                </li>
                            ))
                            : null}
                        {doc_ver_mas === "acta_asamblea" ? null : (
                            <li>
                              <button
                                  onClick={this.verMas.bind(this, "acta_asamblea")}
                                  type="button"
                                  className="btn btn-link color-nevasa font-size-13"
                              >
                                Ver más <i className="fa fa-angle-double-down"></i>
                              </button>
                            </li>
                        )}
                      </ul>
                  ) : null}
                </div>
                <div className="col-lg-2 text-center">
                  <div className="border-left pl-3">
                    <button
                        type="button"
                        className={`btn btn-link btn-fondo text-uppercase font-size-13 ${
                            doc_seleccionado === "valores" ? "color-nevasa" : ""
                        }`}
                        onClick={this.verDocumentos.bind(this, "valores")}
                    >
                      Valores Cuota
                    </button>
                  </div>
                  {fondo.valores_cuota && fondo.valores_cuota.length > 0 ? (
                      <ul
                          className={`p-4 ${
                              doc_seleccionado === "valores" ? "" : "d-none"
                          }`}
                      >
                        {fondo.valores_cuota
                            ? fondo.valores_cuota.map((documento, x) => (
                                <li
                                    key={x}
                                    className={
                                      x < 4 || doc_ver_mas === "valores"
                                          ? ""
                                          : "d-none"
                                    }
                                >
                                  <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={`${
                                          documento.pdf_nombre
                                              ? `${url_api}/static/pdf/${documento.pdf_nombre}`
                                              : documento.url
                                      }`}
                                  >
                                    <i
                                        className="fa fa-file"
                                        style={{color: "#fa4616", fontSize: "16px"}}
                                    />
                                    <p style={{color: "#333", fontSize: "13px"}}>
                                      {documento.descripcion}
                                    </p>
                                  </a>
                                </li>
                            ))
                            : null}
                        {doc_ver_mas === "valores" ? null : (
                            <li>
                              <button
                                  onClick={this.verMas.bind(this, "valores")}
                                  type="button"
                                  className="btn btn-link color-nevasa font-size-13"
                              >
                                Ver más <i className="fa fa-angle-double-down"></i>
                              </button>
                            </li>
                        )}
                      </ul>
                  ) : null}
                </div>
                <div className="col-lg-2 text-center">
                  <div className="border-left pl-3">
                    <button
                        type="button"
                        className={`btn btn-link btn-fondo text-uppercase font-size-13 ${
                            doc_seleccionado === "estados" ? "color-nevasa" : ""
                        }`}
                        onClick={this.verDocumentos.bind(this, "estados")}
                    >
                      Estados Financieros
                    </button>
                  </div>
                  {fondo.estados_financieros &&
                  fondo.estados_financieros.length > 0 ? (
                      <ul
                          className={`p-4 ${
                              doc_seleccionado === "estados" ? "" : "d-none"
                          }`}
                      >
                        {fondo.estados_financieros
                            ? fondo.estados_financieros.map((documento, x) => (
                                <li
                                    key={x}
                                    className={
                                      x < 4 || doc_ver_mas === "estados"
                                          ? ""
                                          : "d-none"
                                    }
                                >
                                  <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={`${
                                          documento.pdf_nombre
                                              ? `${url_api}/static/pdf/${documento.pdf_nombre}`
                                              : documento.url
                                      }`}
                                  >
                                    <i
                                        className="fa fa-file"
                                        style={{color: "#fa4616", fontSize: "16px"}}
                                    />
                                    <p style={{color: "#333", fontSize: "13px"}}>
                                      {documento.descripcion}
                                    </p>
                                  </a>
                                </li>
                            ))
                            : null}
                        {doc_ver_mas === "estados" ? null : (
                            <li>
                              <button
                                  onClick={this.verMas.bind(this, "estados")}
                                  type="button"
                                  className="btn btn-link color-nevasa font-size-13"
                              >
                                Ver más <i className="fa fa-angle-double-down"></i>
                              </button>
                            </li>
                        )}
                      </ul>
                  ) : null}
                </div>
                <div className="col-lg-2 text-center">
                  <div className="border-left pl-3">
                    <button
                        type="button"
                        className={`btn btn-link btn-fondo text-uppercase font-size-13 ${
                            doc_seleccionado === "reglamento" ? "color-nevasa" : ""
                        }`}
                        onClick={this.verDocumentos.bind(this, "reglamento")}
                    >
                      Reglamento Interno
                    </button>
                  </div>
                  {fondo.reglamento_interno &&
                  fondo.reglamento_interno.length > 0 ? (
                      <ul
                          className={`p-4 ${
                              doc_seleccionado === "reglamento" ? "" : "d-none"
                          }`}
                      >
                        {fondo.reglamento_interno
                            ? fondo.reglamento_interno.map((documento, x) => (
                                <li
                                    key={x}
                                    className={
                                      x < 4 || doc_ver_mas === "reglamento"
                                          ? ""
                                          : "d-none"
                                    }
                                >
                                  <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={`${
                                          documento.pdf_nombre
                                              ? `${url_api}/static/pdf/${documento.pdf_nombre}`
                                              : documento.url
                                      }`}
                                  >
                                    <i
                                        className="fa fa-file"
                                        style={{color: "#fa4616", fontSize: "16px"}}
                                    />
                                    <p style={{color: "#333", fontSize: "13px"}}>
                                      {documento.descripcion}
                                    </p>
                                  </a>
                                </li>
                            ))
                            : null}
                        {doc_ver_mas === "reglamento" ? null : (
                            <li>
                              <button
                                  onClick={this.verMas.bind(this, "reglamento")}
                                  type="button"
                                  className="btn btn-link color-nevasa font-size-13"
                              >
                                Ver más <i className="fa fa-angle-double-down"></i>
                              </button>
                            </li>
                        )}
                      </ul>
                  ) : null}
                </div>
                <div className="col-lg-2 text-center">
                  <div className="border-left pl-0">
                    <button
                        type="button"
                        className={`btn btn-link btn-fondo text-uppercase font-size-13 ${
                            doc_seleccionado === "factsheet" ? "color-nevasa" : ""
                        }`}
                        onClick={this.verDocumentos.bind(this, "factsheet")}
                    >
                      Factsheet Clientes
                    </button>
                  </div>
                  {fondo.factsheet && fondo.factsheet.length > 0 ? (
                      <ul
                          className={`p-4 ${
                              doc_seleccionado === "factsheet" ? "" : "d-none"
                          }`}
                      >
                        {fondo.factsheet
                            ? fondo.factsheet.map((documento, x) => (
                                <li
                                    key={x}
                                    className={
                                      x < 4 || doc_ver_mas === "factsheet"
                                          ? ""
                                          : "d-none"
                                    }
                                >
                                  <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={`${
                                          documento.pdf_nombre
                                              ? `${url_api}/static/pdf/${documento.pdf_nombre}`
                                              : documento.url
                                      }`}
                                  >
                                    <i
                                        className="fa fa-file"
                                        style={{color: "#fa4616", fontSize: "16px"}}
                                    />
                                    <p style={{color: "#333", fontSize: "13px"}}>
                                      {documento.descripcion}
                                    </p>
                                  </a>
                                </li>
                            ))
                            : null}
                        {doc_ver_mas === "factsheet" ? null : (
                            <li>
                              <button
                                  onClick={this.verMas.bind(this, "factsheet")}
                                  type="button"
                                  className="btn btn-link color-nevasa font-size-13"
                              >
                                Ver más <i className="fa fa-angle-double-down"></i>
                              </button>
                            </li>
                        )}
                      </ul>
                  ) : null}
                </div>
                <div className="col-lg-2 text-center">
                  <div className="border-left pl-0">
                    <button
                        type="button"
                        className={`btn btn-link btn-fondo text-uppercase font-size-13 ${
                            doc_seleccionado === "folletos" ? "color-nevasa" : ""
                        }`}
                        onClick={this.verDocumentos.bind(this, "folletos")}
                    >
                      Folletos Normativos
                    </button>
                  </div>
                  {fondo.folletos_informativos &&
                  fondo.folletos_informativos.length > 0 ? (
                      <ul
                          className={`p-4 ${
                              doc_seleccionado === "folletos" ? "" : "d-none"
                          }`}
                      >
                        {fondo.folletos_informativos
                            ? fondo.folletos_informativos.map((documento, x) => (
                                <li
                                    key={x}
                                    className={
                                      x < 4 || doc_ver_mas === "folletos"
                                          ? ""
                                          : "d-none"
                                    }
                                >
                                  <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={`${
                                          documento.pdf_nombre
                                              ? `${url_api}/static/pdf/${documento.pdf_nombre}`
                                              : documento.url
                                      }`}
                                  >
                                    <i
                                        className="fa fa-file"
                                        style={{color: "#fa4616", fontSize: "16px"}}
                                    />
                                    <p style={{color: "#333", fontSize: "13px"}}>
                                      {documento.descripcion}
                                    </p>
                                  </a>
                                </li>
                            ))
                            : null}
                        {doc_ver_mas === "folletos" ? null : (
                            <li>
                              <button
                                  onClick={this.verMas.bind(this, "folletos")}
                                  type="button"
                                  className="btn btn-link color-nevasa font-size-13"
                              >
                                Ver más <i className="fa fa-angle-double-down"></i>
                              </button>
                            </li>
                        )}
                      </ul>
                  ) : null}
                </div>


              </div>
            </div>
          )}
        </div>
        <Footer/>
      </>
    );
  };
}

Fondo.propTypes = {
  obtenerFondo: PropTypes.func.isRequired,
  fondo: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  fondo: state.auth.fondo,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({obtenerFondo}, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Fondo));
