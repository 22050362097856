import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import {
  cambioContrasena,
  limpiarMensajes,
} from "../../redux/actions/auth/action";
import { Banner, Footer, Header } from "../general";

export class RecuperarContrasena extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      loading_guardar: "",
    };
  }

  componentWillUnmount = () => {
    const { limpiarMensajes } = this.props;
    limpiarMensajes();
  };

  onClickRecuperarContrasena = (e) => {
    const { cambioContrasena } = this.props;
    const { email } = this.state;
    var loading = SweetAlert.fire({
      title: "Recuperar Contraseña",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      text: "Validando la información ingresada, espere unos segundos",
      icon: "info",
      showConfirmButton: false,
      willOpen: () => {
        SweetAlert.showLoading();
      },
    });
    this.setState({
      loading_guardar: loading,
    });
    cambioContrasena(email);
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render = () => {
    const { is_authenticated, errores_pass, guardar_pass, limpiarMensajes } =
      this.props;
    const { email, loading_guardar } = this.state;
    if (is_authenticated) {
      return <Navigate to="/cuenta" />;
    }
    if (Object.entries(guardar_pass).length > 0) {
      limpiarMensajes();
      loading_guardar.close();
      SweetAlert.fire({
        title: "Recuperar contraseña",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        text: guardar_pass.message,
        type: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-thumbs-up"></i>',
        customClass: {
          confirmButton: "btn btn-primary mr-3",
        },
        buttonsStyling: false,
      });
    }
    if (Object.entries(errores_pass).length > 0) {
      limpiarMensajes();
      loading_guardar.close();
      SweetAlert.fire({
        title: "Recuperar contraseña",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        text: errores_pass.data.message,
        type: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
    }
    return (
      <>
        <Header />
        <Banner banner="inicio_sesion" path="/recuperar-contrasena" />
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <h6>
                  Ingresa tu email y recibirás un email para recuperar la
                  contraseña de tu cuenta
                </h6>
                <form>
                  <div className="form-group">
                    <label>
                      <i className="fa fa-envelope"></i> Email{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Ingresa su email"
                      onChange={this.onChange}
                      value={email}
                    />
                  </div>
                  <div className="button-box">
                    <div className="d-grid gap-2 mt-3 mb-3">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.onClickRecuperarContrasena}
                      >
                        Recuperar contraseña{" "}
                        <i className="fas fa-paper-plane ml-1"></i>
                      </button>
                    </div>
                    <div className="text-center">
                      <Link to="/inicio-sesion">
                        <i className="fa fa-angle-left"></i> Volver al Inicio de
                        Sesión
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  };
}

RecuperarContrasena.propTypes = {
  cambioContrasena: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  is_authenticated: PropTypes.bool,
  errores_pass: PropTypes.object,
  guardar_pass: PropTypes.object,
};

const mapStateToProps = (state) => ({
  is_authenticated: state.auth.is_authenticated,
  errores_pass: state.auth.errores_pass,
  guardar_pass: state.auth.guardar_pass,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ cambioContrasena, limpiarMensajes }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecuperarContrasena);
